<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Sig-Mind
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Resetar a senha 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Sua nova senha deverá ser diferente da atual
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- password -->
            <b-form-group
              label="Nova senha"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Nova Senha"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                    autocomplete="on"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirmar senha"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirmar Senha"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                    autocomplete="on"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              Salvar alterações
            </b-button>
          </b-form>
        </validation-observer>

        <div
          v-if="carregando"
          class="text-center"
        >
          <br>
          <b-spinner class="mr-1 spinner-border text-primary" />
        </div>

        <p class="text-center mt-2">
          <b-link @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" /> Retornar para a página
            anterior
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from "vee-validate"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BSpinner
} from "bootstrap-vue"
import { required } from "@validations"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils"
import useJwt from "@/auth/jwt/useJwt"

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BSpinner
  },
  data() {
    return {
      carregando: false,
      usuarioLocalStorage: null,
      userEmail: "",
      cPassword: "",
      password: "",
      // validation
      required,

      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password"
    }
  },
  beforeMount() {
    this.preparaUsuario()
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    }
  },
  methods: {
    preparaUsuario() {
      this.usuarioLocalStorage = getUserData()
      // console.log('this.usuarioLocalStorage')
      // console.log(this.usuarioLocalStorage)
    },
    async alterarSenha() {
      this.carregando = true

      const resp = await useJwt
        .alterarSenha({
          emailDoUsuario: this.usuarioLocalStorage.emailDoUsuario,
          senhaNovaDoUsuario: this.password
        })
        .then(response => response)
        .catch(response => response)

      setTimeout(() => {
        this.carregando = false
        try {
          const retorno = resp.data
          const usuarioLogado = this.usuarioLocalStorage

          if (retorno.sucesso) {
            this.$router
              .push({
                name: getHomeRouteForLoggedInUser(
                  this.$store.state.user.idDoPerfil
                )
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  timeout: 5000,
                  props: {
                    title: `Bem vindo ${usuarioLogado.nomeDoUsuario}!`,
                    icon: "SmileIcon",
                    variant: "success",
                    text:
                      "Sua nova senha foi salva com sucesso! Sinta-se a vontade para nos chamar sempre que precisar."
                  }
                })
              })
            return
          }
        } catch (error) {
          // console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: "top-center",
            timeout: 5000,
            props: {
              title: "Você inseriu a senha corretamente nos dois campos?",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text:
                "Por gentileza verifique os dados novamente. Caso necessário gere uma nova senha através do link para redefinição"
            }
          })
        }
      }, 7000)
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password"
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password"
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.alterarSenha()
        }
      })
    }
  }

  //     home() {
  //     this.$router.push({ name: getHomeRouteForLoggedInUser(this.$store.state.user.idDoPerfil) })
  // },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
